import { SortableContext, useSortable } from "@dnd-kit/sortable";
import { useMemo } from "react";
import TaskCard from "./TaskCard";
import styled from "styled-components";
import { Column, Task } from "./Kanban";

export type Id = string | number;

const Container = styled.div`
    background-color: #161C22;
    width: 350px;
    height: calc(100vh - 160px);
    max-height: auto;
    border-radius: 0.375rem;
    display: flex;
    flex-direction: column;
`;

const ContainerTitle = styled.div`
    font-size: 1rem; 
    height: 55px;
    border-radius: 11px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    padding: 0.75rem; 
    font-weight: bold;
    border: 8px solid #161C22;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    user-select: none;
`;

const Title = styled.div`
    display: flex;
    gap: 0.5rem; 
`;

const Icon = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #EAF4FD;
    padding: 0.15rem 0.3rem; 
    font-size: 20px; 
    border-radius: 9999px; 
`;

const Wrapper = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    gap: 1rem;
    padding: 0.5rem; 
    overflow-x: hidden;
    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: rgb(172, 172, 172); 
        border-radius: 6px; 
    }

    &::-webkit-scrollbar-thumb:hover {
        background-color: #999; 
    }
`;

interface Props {
    column: Column;
    updateTask: (id: Id, content: string) => void;
    deleteTask: (id: Id) => void;
    tasks: Task[];
}

function ColumnContainer({ column, tasks, deleteTask, updateTask, }: Props) {
    const tasksIds = useMemo(() => {
        return tasks.map((task) => task.id);
    }, [tasks]);

    const { setNodeRef } = useSortable({
        id: column.id,
        data: {
            type: "Column",
            column,
        },
    });

    return (
        <Container ref={setNodeRef}>
            <ContainerTitle style={{ backgroundColor: column.color }}>
                <Title>
                    <Icon style={{ color: column.color }}>{column.icon}</Icon>
                    {column.title}
                </Title>
            </ContainerTitle>

            <Wrapper>
                <SortableContext items={tasksIds}>
                    {tasks.map((task) => (
                        <TaskCard
                            key={task.id}
                            task={task}
                            deleteTask={deleteTask}
                            updateTask={updateTask}
                            color={column.color}
                        />
                    ))}
                </SortableContext>
            </Wrapper>
        </Container>
    );
}

export default ColumnContainer;