import { useEffect, useRef, useState } from "react"
import KanbanBoard from "../../components/Kanban/Kanban";
import { useAuth } from "../../contexts/AuthProvider/useAuth";
import { formatDateTime, formatMonetary, toastError } from "../../commons/utils";
import { FiltroRequisicoes } from "./Filtro";

export const RequisicoesKanban = () => {
    const auth = useAuth();

    const refKanbam = useRef<any>();
    const refFiltro = useRef<any>();

    const [status, setStatus] = useState<any>();

    useEffect(() => {
        auth.get('/v1/status')
            .then((response: any) => {
                const filteredData = response.data.filter((item: any) => item.statativo === 1).sort((a: any, b: any) => a.statsequencia - b.statsequencia);

                const maxStatsequencia = filteredData.reduce((max: any, current: any) => (current.statsequencia > max ? current.statsequencia : max), 0);

                const _status = filteredData.filter((item: any) => item.statsequencia !== maxStatsequencia).map((item: any) => ({
                    id: item.statid,
                    title: item.statdescricao,
                    color: item.statcor ? item.statcor.slice(0, -2) : '',
                    value: item.statid,
                }));

                setStatus(_status);
            })
            .catch((error: any) => {
                toastError(error);
            });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    function onGetData(data: any) {
        console.log(data)
        const newArray: any = [];
        refKanbam.current.setTasks([]);

        data.forEach((item: any) => {

            const { reqid, fornome, statid, imobdescricao, reqobservacao, impdescricao, reqnumero, impprioridade, reqdata,
                total_requisitado, reqdesconto, usucodigo } = item;

            const id = `${reqid} - ${usucodigo}`;
            const content =
                <div style={{ paddingTop: '5px' }}>
                    {imobdescricao && <div style={{ paddingBottom: '5px' }}>{imobdescricao}</div>}
                    {fornome && <div style={{ fontSize: '12px', paddingBottom: '7px' }}>{fornome}</div>}
                    {reqobservacao && <div style={{ fontSize: '12px', paddingBottom: '7px' }}>{reqobservacao}</div>}
                    {reqdata && <div style={{ fontSize: '12px', paddingBottom: '7px' }}>{formatDateTime(reqdata)}</div>}
                    {((total_requisitado > 0) || (reqdesconto > 0)) &&
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <div style={{ fontSize: '14px' }}>
                                {reqdesconto ?
                                    <div style={{ fontSize: '12px' }}>{formatMonetary(total_requisitado) + ' - ' + formatMonetary(reqdesconto) + ' = '}
                                        <span style={{ fontSize: '14px' }}>{formatMonetary(total_requisitado - reqdesconto).toString()}</span>
                                    </div> :
                                    formatMonetary(total_requisitado)}
                            </div>
                        </div>
                    }
                </div>
            const columnId = statid;
            const details =
                <div>
                    <div>{impdescricao}</div>
                    <div style={{ fontSize: '14px', fontWeight: '600', color: impprioridade === 1 ? '#f15619' : 'none' }}>{reqnumero + (impprioridade === 1 ? ' - Urgente' : '')}</div>
                </div>
            newArray.push({ id, content, columnId, details });
        });

        refKanbam.current.setTasks(newArray);
    }

    return (
        status &&
        <>
            <FiltroRequisicoes ref={refFiltro} onGetData={onGetData} />
            <KanbanBoard ref={refKanbam} columnsId={status?.map((item: any) => item.id)} status={status} />
        </>
    )
}