import { FaUserLock } from "react-icons/fa";
import { FaBuildingWheat } from "react-icons/fa6";
import PhonelinkSetupIcon from '@mui/icons-material/PhonelinkSetup';
import { HiOutlineSwitchHorizontal } from "react-icons/hi";
import { FaBoxOpen } from "react-icons/fa6";
import { GiLindenLeaf } from "react-icons/gi";
import { TbRulerMeasure } from "react-icons/tb";
import { BsInputCursor } from "react-icons/bs";
import { TbReportAnalytics } from "react-icons/tb";
import { camelize, getApiCEP, getApiCNPJ } from "../commons/utils";
import { FaBuildingCircleCheck } from "react-icons/fa6";

const options_sexo = [
    { value: 0, label: 'Física' },
    { value: 1, label: 'Jurídica' },
    { value: 2, label: 'Estrangeira' }
]

const options_acao_valcomplementares = [
    { value: 0, label: 'Nenhuma' },
    { value: -1, label: 'Subtrair' },
    { value: 1, label: 'Somar' }
]

const options_formato_valcomplementares = [
    { value: 0, label: 'Valor' },
    { value: 1, label: 'Percentual' }
]

export const model_usuario = {
    icon: <FaUserLock />,
    name: 'Usuário',
    key: 'usucodigo',
    description_field: 'usunome',
    inactive_field: 'usuativo',
    api_get: '/v1/usuariocrm',
    api_post: '/v1/usuarioone',
    inputs: [
        { id: 'usuemail', label: 'E-mail', type: 'text', md: 12, required: true, model: 'email' },
        { id: 'usunome', label: 'Nome', type: 'text', md: 12, required: false },
    ],
    base: {
        usunome: ''
    }
}

export const model_status = {
    name: 'Status',
    key: 'statid',
    description_field: 'statdescricao',
    inactive_field: 'statativo',
    api_get: '/v1/status',
    api_post: '/v1/statusone',
    inputs: [
        { id: 'statid', label: 'Código', type: 'text', md: 3, required: true, disabled: true },
        { id: 'statdescricao', label: 'Descrição', type: 'text', md: 9, required: true },
        { id: 'statsequencia', label: 'Sequência', type: 'number', md: 3, required: true },
        { id: 'statacao', label: 'Ação', type: 'text', md: 9, required: true },
        { id: 'statgerapdf', label: 'Gera PDF', type: 'check', md: 3 },
        { id: 'statassinatura', label: 'Assinatura', type: 'check', md: 3 },
    ],
    base: {
        statativo: 1
    }
}

export const model_campos = {
    name: 'Campos',
    key: 'campnome',
    consider_key: true,
    description_field: 'campdescricao',
    api_get: '/v1/campos',
    api_post: '/v1/campo',
    inputs: [
        { id: 'campnome', label: 'Código', type: 'text', md: 5, required: true, disableEdit: true },
        { id: 'campdescricao', label: 'Descrição', type: 'text', md: 7, required: true },
        { id: 'campmsgerro', label: 'Mensagem Erro', type: 'text', md: 12, required: true }
    ],
    base: {
    }
}

export const model_requisicao_campos = {
    name: 'Campos Requisição',
    key: 'none',
    description_field: 'reqcamponome',
    inactive_field: 'reqcampoativo',
    api_get: '/v1/requisicaocamposstatus',
    api_post: '/v1/requisicaocamposone',
    inputs: [
        { id: 'reqcamponome', label: 'Campo', type: 'text', md: 12, required: true },
        { id: 'reqcamposequencia', label: 'Sequência', type: 'text', md: 3, required: true },
        { id: 'statid', label: 'A Partir do Status', type: 'select', md: 9, model: model_status },
        { id: 'reqcampoobrigatorio', label: 'Obrigatório', type: 'check', md: 3 },
    ],
    base: {
        reqcampoativo: 1
    }
}

export const model_grupo_consulta = {
    name: 'Grupo',
    key: 'gruid',
    description_field: 'grudescricao',
    api_get: '/v1/grupoonlyuser',
}

export const model_grupo = {
    icon: <FaBuildingWheat />,
    name: 'Grupo',
    key: 'gruid',
    description_field: 'grudescricao',
    api_get: '/v1/grupoonlyuser',
    api_post: '/v1/newgrupo',
    inputs: [
        { id: 'gruid', label: 'Código', type: 'number', md: 3, disabled: true, required: true },
        { id: 'grudescricao', label: 'Descrição', type: 'text', md: 9, required: true },
        { id: 'gruidpai', label: 'Grupo Pai', type: 'select', md: 12, model: model_grupo_consulta }
    ],
    base: {
        gruativo: 1,
        grutipo: 0
    }
}

export const model_aplicativo_usuario = {
    icon: <PhonelinkSetupIcon />,
    name: 'Liberação de Aplicativos',
    key: 'aplicodigo',
    description_field: 'aplinome',
    api_get: '/v1/aplicativotouser',
}

export const model_cultura = {
    icon: <GiLindenLeaf />,
    name: 'Cultura',
    key: 'cultid',
    description_field: 'cultdescricao',
    api_get: '/v1/cultura',
    api_post: '/v1/culturacrm',
    inputs: [
        { id: 'cultid', label: 'Código', type: 'text', md: 12, disabled: true, required: true },
        { id: 'cultdescricao', label: 'Descrição', type: 'text', md: 12, required: true }
    ],
    base: {}
}

export const model_cultura_etapa = {
    name: 'Cultura Etapa',
    key: 'cultetid',
    description_field: 'cultetdescricao',
    api_get: '/v1/culturaetapa',
    inputs: [
        { id: 'cultetid', label: 'Código', type: 'text', md: 12, disabled: true, required: true },
        { id: 'cultetdescricao', label: 'Descrição', type: 'text', md: 12, required: true }
    ],
    base: {}
}

export const model_produto_unidademedida = {
    icon: <TbRulerMeasure />,
    name: 'Unidade Medida',
    key: 'produnidid',
    description_field: 'produniddescricao',
    inactive_field: 'produnidativo',
    api_get: '/v1/produtounidademedida',
    api_post: '/v1/produtounidademedidacrm',
    inputs: [
        { id: 'produnidid', label: 'Código', type: 'text', md: 12, disabled: true, required: true },
        { id: 'produniddescricao', label: 'Descrição', type: 'text', md: 12, required: true }
    ],
    base: {
        produnidativo: 1
    }
}

function changeCultura(_newValue: any, refs: any) {
    if (_newValue)
        refs.current['cultetid'].setFilter({ cultid: _newValue.value })
    else
        refs.current['cultetid'].clearFilter();
}

export const model_produto = {
    icon: <FaBoxOpen />,
    name: 'Produto',
    key: 'prodid',
    description_field: 'proddescricao',
    inactive_field: 'prodativo',
    api_get: '/v1/produtocrm',
    api_post: '/v1/produtocrm',
    inputs: [
        { id: 'prodid', label: 'Código', type: 'text', md: 12, disabled: true, required: true },
        { id: 'proddescricao', label: 'Descrição', type: 'text', md: 12, required: true },
        { id: 'cultid', label: 'Cultura', type: 'select', model: model_cultura, md: 12, required: true, onAfterChange: changeCultura },
        { id: 'produnididpadrao', label: 'Unidade Padrão', type: 'select', model: model_produto_unidademedida, md: 6, required: true },
        { id: 'produnididminima', label: 'Unidade Mínima', type: 'select', model: model_produto_unidademedida, md: 6, required: true },
        { id: 'cultetid', label: 'Etapa', type: 'select', model: model_cultura_etapa, md: 12, required: true },
        { id: 'produsacategoria', label: 'Usa Categoria', type: 'check', md: 4 },
        { id: 'produsatamanho', label: 'Usa Tamanho', type: 'check', md: 4 },
    ],
    base: {
        prodativo: 1
    }
}

export const model_tipo_movimento = {
    icon: <HiOutlineSwitchHorizontal />,
    name: 'Tipo Movimento',
    key: 'tipmovcontador',
    description_field: 'tipmovdescricao',
    sub_description_field: 'produtodesc',
    inactive_field: 'tipmovativo',
    api_get: '/v1/tipomovivmentocrm',
    api_post: '/v1/tipomovimentocrm',
    inputs: [
        { id: 'tipmovcontador', label: 'Código', type: 'number', md: 3, disabled: true, required: true },
        { id: 'tipmovdescricao', label: 'Descrição', type: 'text', md: 9, required: true },
        { id: 'tipmovacao', label: 'Ação', type: 'text', md: 12, required: true },
        { id: 'prodidentrada', label: 'Produto Entrada', type: 'select', model: model_produto, md: 6, required: false },
        { id: 'prodidsaida', label: 'Produto Saída', type: 'select', model: model_produto, md: 6, required: false },
        { id: 'tipmovperda', label: 'Perda', type: 'check', md: 3 },
        { id: 'tipmovinformapreco', label: 'Informa Preço', type: 'check', md: 4 },
        { id: 'tipmovfinanceiro', label: 'Gera Financeiro', type: 'check', md: 5 },
    ],
    base: {
        tipmovativo: 1
    }
}

export const model_produto_unidade_conversao = {
    name: 'Unidade Conversão',
    api_get: '/v1/produtounidadeconversaocrm',
    api_post: '/v1/produtounidadeconversaocrm',
    inputs: [
        { id: 'produnidid', label: 'Unidade Medida', type: 'select', model: model_produto_unidademedida, md: 12, required: true },
        { id: 'produnididbase', label: 'Unidade Medida Base', type: 'select', model: model_produto_unidademedida, md: 12, required: true },
        { id: 'produnidconvfator', label: 'Fator Conversão', type: 'number', md: 6, required: true }
    ],
    base: {}
}

export const model_movimentoestoque_campos = {
    icon: <BsInputCursor />,
    name: 'Campos Movimento Estoque',
    key: 'none',
    description_field: 'movestcamponome',
    inactive_field: 'movestcampoativo',
    api_get: '/v1/movimentoestoquecamposcrm',
    api_post: '/v1/movimentoestoquecamposone',
    inputs: [
        { id: 'movestcampoobrigatorio', label: 'Obrigatório', type: 'check', md: 12 },
        { id: 'movestcamposequencia', label: 'Sequência', type: 'number', md: 3, required: true, disableEdit: true },
        { id: 'movestcamponome', label: 'Campo', type: 'select', md: 9, model: model_campos, required: true, disableEdit: true },
        { id: 'tipmovcontador', label: 'Tipo Movimento', type: 'select', model: model_tipo_movimento, md: 12, required: true },
    ],
    base: {
        movestcampoativo: 1
    }
}

export const model_plantio = {
    name: 'Plantio',
    key: 'plid',
    description_field: 'pldescricao',
    api_get: '/v1/plantio'
}

export const model_movimentos_por_periodo = {
    icon: <TbReportAnalytics />,
    name: 'Movimentos Por Período',
    api_get: '/v1/movimentosporperiodo'
}

function changeTipoPessoa(_newValue: any, refs: any) {
    refs.current['empcpfcnpj'].setLabel(_newValue.value === 0 ? 'CPF' : _newValue.value === 1 ? 'CNPJ' : 'Documento');
    refs.current['empcpfcnpj'].setModel(_newValue.value === 0 ? 'cpf' : _newValue.value === 1 ? 'cnpj' : '');
}

async function changeCNPJ(_newValue: any, refs: any) {
    if ((_newValue.length === 18) && (refs.current['emptipopessoa'].getValue() === 1)) {
        refs.current['empcpfcnpj'].setMsgInfo('Pesquisando CNPJ...');

        await getApiCNPJ(_newValue)
            .then((data: any) => {
                refs.current['empie'].setValue(camelize(data.estabelecimento?.inscricoes_estaduais[0]?.inscricao_estadual));
                refs.current['empnome'].setValue(camelize(data.razao_social));
                refs.current['empcep'].setValue(data.estabelecimento.cep.replace(/(\d{5})(\d{0,3})/, '$1-$2'));
                refs.current['empendereco'].setValue(camelize(data.estabelecimento.tipo_logradouro) + ' ' + camelize(data.estabelecimento.logradouro));
                refs.current['empendnumero'].setValue(data.estabelecimento.numero);
                refs.current['empbairro'].setValue(camelize(data.estabelecimento.bairro));
                refs.current['empmunicipio'].setValue(data.estabelecimento.cidade.nome);
                refs.current['empuf'].setValue(data.estabelecimento.estado.sigla);
            })
            .catch((error: any) => {
                console.log(error)
            })
            .finally(() => {
                refs.current['empcpfcnpj'].setMsgInfo('')
            })
    }
}

function changeCEP(_newValue: any, refs: any) {
    if (_newValue.length === 9) {
        getApiCEP(_newValue.replace('-', ''))
            .then((data: any) => {
                if (data.logradouro)
                    refs.current['empendereco'].setValue(data.logradouro);

                if (data.bairro)
                    refs.current['empbairro'].setValue(data.bairro);

                refs.current['empmunicipio'].setValue(data.localidade);
                refs.current['empuf'].setValue(data.uf);
            })
            .catch((error: any) => {
                console.log(error)
            })
    }
}

export const model_empresa = {
    icon: <FaBuildingCircleCheck />,
    name: 'Empresa',
    key: 'gruempid',
    api_get: '/v1/grupoempresacrm',
    api_post: '/v1/grupoempresacrm',
    description_field: 'empnome',
    inputs: [
        { id: 'gruid', label: 'Grupo', type: 'select', model: model_grupo, md: 3, required: true, disableEdit: true },
        { id: 'emptipopessoa', label: 'Tipo Pessoa', type: 'select', md: 3, options: options_sexo, onAfterChange: changeTipoPessoa },
        { id: 'empcpfcnpj', label: 'CPF', type: 'text', md: 3, maxLength: 20, onAfterChange: changeCNPJ },
        { id: 'empie', label: 'IE', type: 'text', md: 3, maxLength: 20 },
        { id: 'empnome', label: 'Nome', type: 'text', md: 12, required: true, maxLength: 100 },
        { id: 'empcep', label: 'CEP', type: 'text', md: 3, maxLength: 10, model: 'cep', onAfterChange: changeCEP },
        { id: 'empendereco', label: 'Endereço', type: 'text', md: 7, maxLength: 200 },
        { id: 'empendnumero', label: 'Número', type: 'text', md: 2, maxLength: 10 },
        { id: 'empbairro', label: 'Bairro', type: 'text', md: 5, maxLength: 50 },
        { id: 'empmunicipio', label: 'Município', type: 'text', md: 5, maxLength: 50 },
        { id: 'empuf', label: 'UF', type: 'text', md: 2, maxLength: 2 },
        { id: 'empurllogo', label: 'URL Logo', type: 'text', md: 12, maxLength: 200 },
        { id: 'empobsfixarelatorio', label: 'Obs. Relatório', type: 'text', md: 12, maxLength: 5000, textarea: true, rows: 4 },
    ],
    base: {
    },
    size: 'lg'
}

export const model_valores_complementares = {
    icon: <HiOutlineSwitchHorizontal />,
    name: 'Valores Complementares',
    key: 'valid',
    description_field: 'valnome',
    inactive_field: 'valativo',
    api_get: '/v1/valorescomplementarescrm',
    api_post: '/v1/valorescomplementarescrm',
    inputs: [
        { id: 'valid', label: 'Código', type: 'text', md: 12, disabled: true, required: true },
        { id: 'valnome', label: 'Nome', type: 'text', md: 12, required: true, maxLength: 50 },
        { id: 'valacao', label: 'Ação', type: 'select', options: options_acao_valcomplementares, md: 6, required: true },
        { id: 'valformato', label: 'Formato', type: 'select', options: options_formato_valcomplementares, md: 6, required: true },
        { id: 'valsequencia', label: 'Sequência', type: 'number', md: 4, required: true },
        { id: 'valgrupo', label: 'Grupo', type: 'number', md: 4, required: true },
        { id: 'valvalorpadrao', label: 'Padrão', type: 'number', md: 4 },
        { id: 'valdescricao', label: 'Descrição', type: 'text', md: 12, maxLength: 5000, textarea: true, rows: 4 },
    ],
    base: {
        valativo: 1
    }
}

export const model_fornecedor = {
    name: 'Fornecedor',
    key: 'forid',
    description_field: 'fornome',
    inactive_field: 'forativo',
    api_get: '/v1/fornecedor',
    inputs: [],
    base: {}
}

export const model_imobilizado = {
    name: 'Imobilizado',
    key: 'imobid',
    description_field: 'imobdescricao',
    inactive_field: 'imobativo',
    api_get: '/v1/imobilizado',
    inputs: [],
    base: {}
}