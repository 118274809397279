import { ConsultaPadrao } from "../../components/ConsultaPadrao";
import { model_empresa } from "../../models";

export const GrupoEmpresa = () => {
    const columns = [
        { field: 'gruempid', header: 'Código' },
        { field: 'empnome', header: 'Nome' },
        { field: 'empcpfcnpj', header: 'Documento' },
        { field: 'empie', header: 'IE' },
        { field: 'empcep', header: 'CEP' },
        { field: 'empendereco', header: 'Endereço' },
        { field: 'empendnumero', header: 'Número' },
        { field: 'empbairro', header: 'Bairro' },
        { field: 'empmunicipio', header: 'Município' },
        { field: 'functions', style: { minWidth: '2rem', width: '2rem' } }
    ];

    return (
        <ConsultaPadrao model={model_empresa} columns={columns} sortField="gruempid" />
    )
}