import { formatDecimal } from "../../commons/utils";
import { ConsultaPadrao } from "../../components/ConsultaPadrao";
import { model_valores_complementares } from "../../models";

export const ValoresComplementares = () => {
    const columns = [
        { field: 'valnome', header: 'Nome' },
        { field: 'acao', header: 'Ação' },
        { field: 'formato', header: 'Formato' },
        { field: 'valsequencia', header: 'Sequência', style: { width: '125px' } },
        { field: 'valgrupo', header: 'Grupo', style: { width: '125px' } },
        { field: 'valvalorpadrao', header: 'Padrão', body: (e: any) => formatDecimal(e.valvalorpadrao), dataType: "numeric", style: { width: '125px' } },
        { field: 'valdescricao', header: 'Descrição' },
        { field: 'tipmovativo', header: 'Ativo' },
        { field: 'functions', style: { minWidth: '2rem', width: '2rem' } }
    ];

    return (
        <ConsultaPadrao model={model_valores_complementares} columns={columns} />
    )
}